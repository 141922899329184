

.text .number {
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    transition: all 1s;
}

.text .txt {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #2C2C2C;
}


[data-aos="works"].aos-animate .works__line {
    animation: animWorksArrowLine 7s cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
    animation-delay: .8s;
    animation-duration: 10s;
}

[data-aos="works"].aos-animate .point-1 circle {
    r: 32px;
    transition-delay: 0.2s;
}


[data-aos="works"].aos-animate .point-2 circle {
    r: 32px;
    transition-delay: 1.3s;
}

[data-aos="works"].aos-animate .point-3 circle {
    r: 32px;
    transition-delay: 2.75s;
}


[data-aos="works"].aos-animate .point-4 circle {
    r: 32px;
    transition-delay: 3.9s;
}


.works .point-1 .number {
    opacity: 0;
    /*transform: scale(0);*/
    transition: opacity 2s, transform 1s;
    transition-delay: 1s;
}

.works .point-2 .number {
    opacity: 0;
    /*transform: scale(0);*/
    transition: opacity 2s, transform 2s;
    transition-delay: 1.5s;
}

.works .point-3 .number {
    opacity: 0;
    /*transform: scale(0);*/
    transition: opacity 2s, transform 3s;
    transition-delay: 2s;
}

.works .point-4 .number {
    opacity: 0;
    /*transform: scale(0);*/
    transition: opacity 2s, transform 4s;
    transition-delay: 2s;
}

/*.works .point-1 .text,*/
/*.works .point-3 .text*/
/*{*/
/*    opacity: 0;*/
/*    transform: translateY(-50px);*/
/*    transition: opacity 1s, transform 1s;*/
/*}*/
.works .point-2 .txt,
.works .point-4 .txt,
.works .point-1 .txt,
.works .point-3 .txt {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 1s, transform 1s;
}

[data-aos="works"].aos-animate .point-1 .text {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 1.9s;
}

[data-aos="works"].aos-animate .point-1 .number {
    opacity: 1;
    transform: scale(1);
    transition-delay: 1s;
    transition: opacity 2s, transition-delay 3s;
}

[data-aos="works"].aos-animate .point-2 .text .number {
    opacity: 1;
    transition: opacity 2s, transition-delay 4s;
}

[data-aos="works"].aos-animate .point-3 .text .number {
    opacity: 1;
    transition: opacity 2s, transition-delay 5s;
}

[data-aos="works"].aos-animate .point-4 .text .number {
    opacity: 1;
    transition: opacity 2s, transition-delay 6s;
}

[data-aos="works"].aos-animate .point-1 .text .txt {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.35s;
}

[data-aos="works"].aos-animate .point-2 .text .txt {
    transform: translateX(0);
    opacity: 1;
    transition-delay: 1.35s;
}

[data-aos="works"].aos-animate .point-3 .text .txt {
    transform: translateX(0);
    opacity: 1;
    transition-delay: 2.95s;
}

[data-aos="works"].aos-animate .point-4 .text .txt {
    transform: translateX(0);
    opacity: 1;
    transition-delay: 3.95s;
}






.works-mob__item {
    text-align: center;
}

.works__line {
    stroke-dasharray: 1250;
    stroke-dashoffset: 1250;
}

@keyframes animWorksArrowLine {
    from {
        stroke-dashoffset: 1150;
    }
    to {
        stroke-dashoffset: 0;
    }
}

.works circle {
    r: 0;
    transition: r .5s;
}


.works .line {
    stroke-dasharray: 228;
    stroke-dashoffset: 0;
}

@keyframes animWorksLine {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 228;
    }
}


.works-mob__item-txt {
    padding-top: 5px;
    padding-bottom: 42px;
    font-size: 18px;
    line-height: 22px;
}

/*//////*/
.wrapper2 {
    /*width: 500px;*/
    /*height: 300px;*/
    position: relative;
    /*margin: 60px auto;*/
}


.services-link {
    color: #2c2c2c;
    text-decoration: none;
}


.map-point__popup {
    position: absolute;
    width: 208px;
    bottom: 50%;
    right: 50%;
    padding: 26px;
    border: 1px solid #2C2C2C;
    background: #FFF;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.0927393);
    transform: translate(43%, 54%) scale(0);
    visibility: hidden;
    z-index: 2;
    border-radius: 50%;
    opacity: 0;
    transition: 1s;
    pointer-events: none;
}
.map-point__popup_left {
    left: 0;
    right: auto;
    transform: translate(-39%, 51%) scale(0);
}
.map-point__popup .title_size_20 {
    margin-top: 18px;
    margin-bottom: 7px;
}
.map-point__popup-cities {
    line-height: 25px;
}
.map-point::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: -1;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: #000;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.22);
}
.map-point path {
    transition: fill .35s;
}
.hoverActive.map-point path , .point-active.map-point path{
    fill: #2C2C2C;
}
/*.map-point:hover path {*/
/*    fill: #2C2C2C;*/
/*}*/

/*.map-point:hover .map-point__popup {*/
/*    visibility: visible;*/
/*    transform: translate(233px, 20px) scale(1);*/
/*    border-radius: 6px;*/
/*    opacity: 1;*/
/*}*/
/*.map-point:hover .map-point__popup_left {*/
/*    visibility: visible;*/
/*    transform: translate(-210px, 20px) scale(1);*/
/*    opacity: 1;*/
/*}*/
.point-active .map-point__popup , .hoverActive .map-point__popup{
    visibility: visible;
    transform: translate(233px, 20px) scale(1);
    border-radius: 6px;
    opacity: 1;
}
.point-active .map-point__popup_left, .hoverActive .map-point__popup_left{
    visibility: visible;
    transform: translate(-210px, 20px) scale(1);
    opacity: 1;
}

.point-item.hoverActive {
    z-index: 100;
}

.point-1 {
    top: 42%;
    left: 23.5%;
}
.point-2 {
    top: 48%;
    left: 18.5%;
}
.point-3 {
    top: 35%;
    left: 24%;
    z-index: 0;
}
.point-4 {
    top: 33%;
    left: 55.5%;
}
.point-5 {
    top: 36%;
    left: 54%;
    z-index: 0;
}

.map-wrapper .map-point {
    transform: translate(-50%, -50%) scale(0);
    transition: transform 1s 0s;
}

[data-aos="map-wrapper"].aos-animate .map-point {
    transform: translate(-50%, -100%) scale(1);
    transition-delay: 1s;
}
[data-aos="map-wrapper"].aos-animate .point-2 {
    transition-delay: 1.5s;
}
[data-aos="map-wrapper"].aos-animate .point-3 {
    transition-delay: 2s;
}
[data-aos="map-wrapper"].aos-animate .point-4 {
    transition-delay: 2.5s;
}
[data-aos="map-wrapper"].aos-animate .point-5 {
    transition-delay: 3s;
}


